import { Link } from '@reach/router';
import React from 'react';

const Unsecured = () => {
  return (
    <div>
      <h2>
        Click{' '}
        <Link to={'/'} title="Web Application" target="_blank">
          here
        </Link>{' '}
        to access WebApp safely.
      </h2>
    </div>
  );
};

export default Unsecured;
