import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core";
import { FaWallet } from "react-icons/fa";
import walletProfileStyle from "./style/walletProfileStyle.js";

const useStyles = makeStyles(walletProfileStyle);

export default function WalletCreator(user) {
  const [inputValue, setInputValue] = useState();
  const classes = useStyles();

  return (
    <div className={classes.wallet_grid}>
      <h3 className={classes.body_title}>Wallet Address</h3>
      <div className={classes.wallet_link}>
        <FaWallet color="red" size="1.4em" />
        <input
          className={classes.wallet_link_input}
          placeholder="0x0000000000000000000000000000000000000000
          "
          onChange={(e) => setInputValue(e.target.value)}
          value={user.user}
        />
      </div>
    </div>
  );
}
