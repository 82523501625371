import React, { useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { navigate } from "@reach/router";
import ModalVideo from "react-modal-video";
import Image from "../../assets/images/about-1.svg";

import { AboutWrap, AboutTextWrap, AboutTextHeading, AboutText } from "./style";
import playIcon from "../../assets/images/play-icon.svg";

const About = () => {
  const [isOpen, setOpen] = useState(false);
  const handleNext = () => {
    navigate("/creator");
  };
  return (
    <AboutWrap>
      <Container fixed>
        <Grid container alignItems="top" spacing={4}>
          <Grid item xs={12} md={6} lg={6}>
            <AboutTextWrap>
              <AboutTextHeading>Learn to Earn</AboutTextHeading>
              <AboutText>
                We designed and developed the & chain protocol to solve the
                issues arising from the disruption of the field of employment,
                the ways we work and learn.
              </AboutText>
            </AboutTextWrap>

            <Button onClick={handleNext}>GET STARTED NOW</Button>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <img src={Image} alt="Lillup" />
          </Grid>
        </Grid>
      </Container>
      <Button
        sx={{
          position: "absolute",
          borderRadius: "50px !important",
          background: "#EB1F24",
          boxShadow: "0px 9px 21px rgba(235, 31, 36, 0.45)",
          alignItems: "center",
          bottom: "-25px",
          width: "74px!important",
          height: "74px!important",
          left: "calc(42% - 3.3%)",
          "@media(min-width: 780px)": {
            bottom: "0",
            left: "calc(50% - 3.3%)",
            width: "94px !important",
            height: "94px !important",
          },
        }}
        onClick={() => setOpen(true)}
      >
        <img src={playIcon} alt="Play Button" />
      </Button>
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="r8DhNCY5Piw"
        onClose={() => setOpen(false)}
        allowFullScreen={true}
      />
    </AboutWrap>
  );
};

export default About;
