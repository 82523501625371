import React, { useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core";
import { FaWallet } from "react-icons/fa";
import { useMoralis } from "react-moralis";

import walletProfileStyle from "./style/walletProfileStyle.js";

const useStyles = makeStyles(walletProfileStyle);

export default function WalletProfile() {
  const [inputValue, setInputValue] = useState();
  const classes = useStyles();
  const { user } = useMoralis();

  return (
    <div>
      <h3 className={classes.body_title}>Wallet Address</h3>
      <div className={classes.wallet_grid}>
        <div>
          <div className={classes.wallet_link}>
            <FaWallet color="red" size="1.4em" />
            <input
              value={user ? user.get("ethAddress") : null}
              disabled={true}
              className={classes.wallet_link_input}
              placeholder="0x7d071B10d62afF72e08B43868e31D9F8A95dCd78"
              onChange={(e) => setInputValue(e.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
