import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';
import { MoralisProvider } from 'react-moralis';
import TagManager from 'react-gtm-module';
import Unsecured from './components/UnSecured/Unsecured';

const APP_ID = 'mtigyTWwbf1mAlSbthfkcmeuXCiKSERVN7XJ3KwI';
const SERVER_URL = 'https://vxoqdpe17hwn.grandmoralis.com:2053/server';

const tagManagerArgs = {
  gtmId: 'G-CDX7YRJGBL',
};

TagManager.initialize(tagManagerArgs);

//TODO: code for clickjacking attack
if (window.self === window.top) {
  ReactDOM.render(
    <React.Suspense fallback="Loading...">
      <MoralisProvider appId={APP_ID} serverUrl={SERVER_URL}>
        <App />
      </MoralisProvider>
    </React.Suspense>,
    document.getElementById('root')
  );
} else {
  ReactDOM.render(<Unsecured />, document.getElementById('root'));
}
